import React from "react";
import "../styles/Terms.css";

const Terms = () => {
  return (
    <div className="terms-container">
      <h1 className="terms-title">Terms and Conditions</h1>
      <p className="terms-text">
        Welcome to KANNUR DISTRICT PETROLEUM DEALERS WELFARE & CHARITABLE TRUST.
        These Terms and Conditions govern your use of our website and services.
        By accessing or using our site, you agree to comply with these terms.
      </p>

      <h2 className="terms-section-title">1. Acceptance of Terms</h2>
      <p className="terms-text">
        By accessing our website, you agree to be bound by these Terms and
        Conditions, all applicable laws, and regulations. If you disagree with
        any part of these terms, please do not use our site.
      </p>

      <h2 className="terms-section-title">2. Use of Site</h2>
      <p className="terms-text">
        You are permitted to use our website for lawful purposes only. You agree
        not to engage in any conduct that may harm our company, disrupt the
        site, or violate any laws.
      </p>

      <h2 className="terms-section-title">3. Intellectual Property</h2>
      <p className="terms-text">
        All content on this site, including text, images, logos, and trademarks,
        is the property of KANNUR DISTRICT PETROLEUM DEALERS WELFARE &
        CHARITABLE TRUST and protected by copyright and trademark laws.
        Unauthorized use of our content is prohibited.
      </p>

      <h2 className="terms-section-title">4. Limitation of Liability</h2>
      <p className="terms-text">
        We are not liable for any damages that may occur from your use of our
        site, including direct, indirect, incidental, or consequential damages.
      </p>

      <h2 className="terms-section-title">5. Changes to Terms</h2>
      <p className="terms-text">
        We may update these Terms and Conditions periodically. By continuing to
        use the site, you agree to be bound by the current version of these
        terms.
      </p>

      <h2 className="terms-section-title">6. Governing Law</h2>
      <p className="terms-text">
        These terms are governed by and construed in accordance with the laws of
        Kerala, and you irrevocably submit to the exclusive jurisdiction of the
        courts in that location.
      </p>

      <h2 className="terms-section-title">7. Contact Information</h2>
      <address className="terms-address">
        KANNUR DISTRICT PETROLEUM DEALERS WELFARE & CHARITABLE TRUST
        <br />
        RASHTRA Deepika road
        <br />
        Pallikkunnu, Kannur, Kerala 670004
        <br />
        Email: kdpdwct@gmail.com
        <br />
        Phone: +91 6238204046
      </address>
    </div>
  );
};

export default Terms;
