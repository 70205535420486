import React from "react";
import "../styles/About.css";
import MissionVisionComponent from "../components/MissionVisionComponent";
import Team from "../components/Team";

function AboutPage() {
  return (
    <div style={{ margin: "20px" }}>
      <div className="about">
        <h1>About Us</h1>
        <p>
          KANNUR DISTRICT PETROLEUM DEALERS WELFARE & CHARITABLE TRUST is
          unitedly derived since 2022 from the integrity of KANNUR DISTRICT
          PETROLEUM DEALERS ASSOCIATION, which is an association in petroleum
          Retail outlet sector at Kannur. We (KDPDWCT) represent the vested
          interest of petroleum dealers at this region with an aim of minimising
          the operational efforts of dealers of all Oil Marketing Companies. We
          are committed to safeguard and timely support apart from legitimate
          requisites. With the exceptional skills & effort of our Founder
          Leaders Sri EM Sasheendran (Chairman), Sri KV Ramachandran (Gen.
          Secretary), Sri KV Sudhan (Treasurer) and with the heartfelt support
          from our esteemed members, we are able to build an office cum Trade
          centre with innovative standard facilitated Auditorium at PALLIKUNNU
          within the City of Kannur. Now, this has grown as a reliable support
          centre for all dealers in Kerala. Our focus is on the Social and
          Charitable aspects of the associate members indented to supply daily
          operational services and commodities.
        </p>
      </div>
      <div style={{ padding: "0px 50px" }}>
        <MissionVisionComponent />
      </div>
      <Team />
    </div>
  );
}

export default AboutPage;
