import React from "react";
import Services from "../components/Services";

function ServicesPage() {
  return (
    <div style={{ marginTop: "50px" }}>
      <Services />
    </div>
  );
}

export default ServicesPage;
