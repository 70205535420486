import React from "react";
import "../styles/CancelPolicy.css";

const CancelPolicy = () => {
  return (
    <div className="cancel-policy-container">
      <h1 className="cancel-policy-title">Cancellation Policy</h1>
      <p className="cancel-policy-text">
        This Cancellation Policy outlines the terms and conditions for canceling
        orders or services with KANNUR DISTRICT PETROLEUM DEALERS WELFARE &
        CHARITABLE TRUST.
      </p>

      <h2 className="cancel-policy-section-title">1. Order Cancellations</h2>
      <p className="cancel-policy-text">
        Orders can be canceled within 24 hours of placement without any charge.
        After 24 hours, cancellations may incur a restocking fee or other
        charges depending on the status of the order.
      </p>

      <h2 className="cancel-policy-section-title">2. Service Cancellations</h2>
      <p className="cancel-policy-text">
        Cancellations for scheduled services must be made at least 48 hours
        before the scheduled date to avoid fees. Late cancellations may be
        subject to a cancellation fee.
      </p>

      <h2 className="cancel-policy-section-title">3. Refunds</h2>
      <p className="cancel-policy-text">
        Refunds for canceled orders will be processed within 5-7 business days.
        If a cancellation fee applies, it will be deducted from the refund
        amount.
      </p>

      <h2 className="cancel-policy-section-title">4. Changes to this Policy</h2>
      <p className="cancel-policy-text">
        We reserve the right to update or change this Cancellation Policy at any
        time. Any changes will be posted on this page, and continued use of our
        services implies acceptance of the revised policy.
      </p>

      <h2 className="cancel-policy-section-title">5. Contact Us</h2>
      <address className="cancel-policy-address">
        KANNUR DISTRICT PETROLEUM DEALERS WELFARE & CHARITABLE TRUST
        <br />
        RASHTRA Deepika road
        <br />
        Pallikkunnu, Kannur, Kerala 670004
        <br />
        Email: kdpdwct@gmail.com
        <br />
        Phone: +91 6238204046
      </address>
    </div>
  );
};

export default CancelPolicy;
