import React, { useState } from "react";
import axios from "axios";
import "../styles/contactUs.css";

const Contact = () => {
  const [name, setName] = useState("");
  const [mail, setMail] = useState("");
  const [description, setDescription] = useState("");

  const handleFormSubmit = (event) => {
    event.preventDefault();
    axios({
      url: "https://lunarsenterprises.com:6013/kdpetroleum/contact-us",
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      data: {
        name: name,
        email: mail,
        message: description,
      },
    })
      .then((data) => {
        if (data.data.result === true) {
          console.log(data.data.message);
        } else {
          console.log("Submission failed");
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  return (
    <div className="contact-container">
      <div className="contact-header">
        <h1>Contact Us</h1>
        <p>
          We’d love to hear from you! Whether you have questions about our
          programs or just need more information, feel free to reach out to us.
        </p>
      </div>

      <div className="contact-content">
        <div className="contact-form-section">
          <h2>Get in Touch</h2>
          <form onSubmit={handleFormSubmit}>
            <input
              type="text"
              placeholder="Your Name"
              value={name}
              onChange={(e) => setName(e.target.value)}
              required
            />
            <input
              type="email"
              placeholder="Your Email"
              value={mail}
              onChange={(e) => setMail(e.target.value)}
              required
            />
            <textarea
              placeholder="Write your comments here"
              rows="5"
              value={description}
              onChange={(e) => setDescription(e.target.value)}
              required
            ></textarea>
            <button type="submit">Send Message</button>
          </form>
        </div>

        <div className="contact-map">
          <iframe
            title="location-map"
            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d249865.98535131404!2d75.06136679453122!3d11.894222099999999!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3ba43df7e566c5d7%3A0x37a0a808cb20ddef!2sKannur%20District%20Petroleum%20Dealers%20Welfare%20%26%20Charitable%20Trust!5e0!3m2!1sen!2sin!4v1730461454296!5m2!1sen!2sin"
            width="100%"
            height="100%"
            allowFullScreen=""
            loading="lazy"
          ></iframe>
        </div>
      </div>
      <div className="contact-info">
        <div className="info-item">
          <i className="fa fa-map-marker"></i>
          <p>
            Kannur Petroleum, RASHTRA Deepika road, Pallikkunnu, Kannur, Kerala
            670004
          </p>
        </div>
        <div className="info-item">
          <i className="fa fa-phone"></i>
          <p>+91 6238204046</p>
        </div>
        <div className="info-item">
          <i className="fa fa-envelope"></i>
          <p> kdpdwct@gmail.com</p>
        </div>
      </div>  
    </div>
  );
};

export default Contact;
