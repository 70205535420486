import React from "react";
import "../styles/MissionVisionComponent.css";

const MissionVisionComponent = () => {
  return (
    <div className="mv-container">
      <div className="mv-section mv-mission">
        <h2 className="ms-heading">Our Mission</h2>
        <p className="ms-text">
          To design, install and maintain cutting edge service equipments that
          ensure the safety, convenience and productivity of Petroleum retailers
          while fostering strong relationships with customers and Oil Marketing
          Companies.
        </p>
        <ul className="ms-list">
          <li className="ms-list-item">
            Design, install, and maintain advanced service equipment.
          </li>
          <li className="ms-list-item">
            Ensure safety, convenience, and productivity for petroleum
            retailers.
          </li>
          <li className="ms-list-item">
            Build strong relationships with customers and Oil Marketing
            Companies.
          </li>
        </ul>
      </div>
      <div
        className="ms-image-container"
        style={{ backgroundImage: require("../assets/images/mission.jpg") }}
      ></div>

      <div
        className="mv-image-container"
        style={{ backgroundImage: require("../assets/images/vision.jpg") }}
      ></div>
      <div className="mv-section mv-vision">
        <h2 className="mv-heading">Our Vision</h2>
        <p className="mv-text">
          To be the leading providers of Reliable, Efficient, and Innovative
          Service equipment solutions for Petrol stations and Tanker
          Trucks.Ensuring quality products with economical outlooks with
          exceptional operational excellence by environmental sustainability.
        </p>
        <ul className="mv-list">
          <li className="mv-list-item">
            Lead in reliable, efficient, and innovative equipment solutions for
            petrol stations and tanker trucks.
          </li>
          <li className="mv-list-item">
            Provide quality, cost-effective products.
          </li>
          <li className="mv-list-item">
            Focus on operational excellence and environmental sustainability.
          </li>
        </ul>
      </div>
    </div>
  );
};

export default MissionVisionComponent;
