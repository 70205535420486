import React, { useEffect } from "react";
import { motion, useAnimation } from "framer-motion";
import { useInView } from "react-intersection-observer";
import "../styles/ChairmanMessage.css";
import chairman from "../assets/images/Sasheendran.jpg";

const ChairmanMessage = () => {
  const controls = useAnimation();
  const [ref, inView] = useInView({
    triggerOnce: true,
    threshold: 0.2,
  });

  useEffect(() => {
    if (inView) {
      controls.start({
        opacity: 1,
        y: 0,
        transition: { duration: 0.6, ease: "easeOut" },
      });
    }
  }, [controls, inView]);

  return (
    <section className="chairman-message-section">
      <motion.div
        className="chairman-message-container"
        ref={ref}
        initial={{ opacity: 0, y: 50 }}
        animate={controls}
      >
        <div className="chairman-image">
          <img src={chairman} alt="Chairman" />
        </div>

        <div className="chairman-content">
          <h2>Voice Of Chairman</h2>
          <p className="chairman-quote">
            "Over the past years, our commitment to innovation and quality has
            been the cornerstone of our growth. As we continue to navigate the
            evolving needs of the petroleum industry, we remain dedicated to
            delivering solutions that not only meet today’s standards but
            anticipate tomorrow’s challenges. Our focus on safety, efficiency,
            and sustainability drives every decision we make."
          </p>

          <div className="chairman-signature">
            <div>
              <h3>Sasheendran</h3>
              <p>Chairman of Petron</p>
            </div>
          </div>
        </div>
      </motion.div>
    </section>
  );
};

export default ChairmanMessage;
