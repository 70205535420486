import React from "react";
import Slider from "react-slick";
import "../styles/WhyChooseUs.css";
import women from "../assets/images/woman.jpg";
import { FaHelmetSafety, FaPersonDress } from "react-icons/fa6";
import { FaUser } from "react-icons/fa";
import ChairmanMessage from "./ChairmanMessage.js";
import bpcl from "../assets/images/bpcl-kurti-500x500.webp";
import bpclmale from "../assets/images/bpcl-uniform-png.png";
import HPCL from "../assets/images/hpcl-petrol-pump-uniform-500x500.webp";
import Hpclladies from "../assets/images/HPCL ladies.jpg";
import IOCL from "../assets/images/IOCL-NEW-Kurti-ladies.jpg";
import IOCLmale from "../assets/images/indian-oil-pump-uniforms.png";
import { useNavigate } from "react-router-dom";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

const WhyChooseUs = () => {
  const navigate = useNavigate();
  const products = [
    {
      id: 1,
      image: bpclmale,
      title: "BPCL Uniform set for gents",
      price: 725.0,
      rating: 4.6,
    },
    {
      id: 2,
      image: bpcl,
      title: "BPCL Uniform set for ladies",
      price: 172.0,
      rating: 4.9,
    },
    {
      id: 3,
      image: HPCL,
      title: "HPCL Uniform set for gents",
      price: 1199.0,
      rating: 4.2,
    },
    {
      id: 4,
      image: Hpclladies,
      title: "HPCL Uniform set for ladies",
      price: 89.0,
      rating: 4.6,
    },
    {
      id: 5,
      image: IOCLmale,
      title: "HPCL Uniform set for gents",
      price: 1199.0,
      rating: 4.2,
    },
    {
      id: 6,
      image: IOCL,
      title: "HPCL Uniform set for ladies",
      price: 89.0,
      rating: 4.6,
    },
  ];

  const addToCart = (product) => {
    const cartItems = JSON.parse(localStorage.getItem("cartItems")) || [];
    const existingItem = cartItems.find((item) => item.id === product.id);
    if (existingItem) {
      existingItem.quantity += 1;
    } else {
      cartItems.push({ ...product, quantity: 1 });
    }
    localStorage.setItem("cartItems", JSON.stringify(cartItems));
    navigate("/cart");
  };

  const sliderSettings = {
    infinite: true,
    speed: 500,
    slidesToShow: window.innerWidth <= 768 ? 1 : 2,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 3000,
    responsive: [
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
        },
      },
    ],
  };

  return (
    <>
      <section className="why-choose-us-section">
        <div className=" row">
          <div className="why-choose-us-left col-md-6">
            <div className="reasons-list">
              <h2>Why People Choose Us</h2>
              <div className="reason-item">
                <div className="icon-circle">
                  <FaPersonDress />
                </div>
                <div>
                  <h3>Complete Solutions for Your Petrol Pump</h3>
                  <p>
                    All petrol pump related products and services under one
                    roof.
                  </p>
                </div>
              </div>

              <div className="reason-item">
                <div className="icon-circle">
                  <FaUser />
                </div>
                <div>
                  <h3>Top Choices, Expertly Delivered</h3>
                  <p>
                    Quality products and services selected and executed by
                    experienced petroleum dealers.
                  </p>
                </div>
              </div>

              <div className="reason-item">
                <div className="icon-circle">
                  <FaHelmetSafety />
                </div>
                <div>
                  <h3>Safety Equipments</h3>
                  <p>
                    Top-notch safety equipment solutions for optimal protection
                    and peace of mind.
                  </p>
                </div>
              </div>
            </div>
          </div>

          <div className="why-choose-us-right col-md-6">
            <div className="product-display-container">
              <div className="product-header">
                <h2>Our Products</h2>
                <button
                  className="view-all-button"
                  onClick={() => navigate("/product")}
                >
                  View All
                </button>
              </div>
              <Slider {...sliderSettings} className="product-slider">
                {products.map((product) => (
                  <div className="product-card" key={product.id}>
                    <img
                      src={product.image}
                      alt={product.title}
                      className="product-image"
                    />
                    <div className="product-details">
                      <h3 className="product-title">{product.title}</h3>
                      <p className="product-price">
                        {/* ₹{product.price.toFixed(2)} */}
                      </p>
                    </div>
                  </div>
                ))}
              </Slider>
            </div>
          </div>
        </div>
      </section>
      <ChairmanMessage />
    </>
  );
};

export default WhyChooseUs;
