import React, { useState, useEffect } from "react";
import "../styles/Navbar.css";
import { FaBars, FaTimes } from "react-icons/fa";
import { FaCartShopping } from "react-icons/fa6";
import { Link } from "react-router-dom";
import icon from "../assets/images/kannur.png";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const Navbar = () => {
  const [isMobile, setIsMobile] = useState(false);
  const [isShrink, setIsShrink] = useState(false);
  const cartss = localStorage.getItem("CartLength");

  const handleMenuToggle = () => {
    setIsMobile(!isMobile);
  };

  const handleScroll = () => {
    setIsShrink(window.scrollY > 50);
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <>
      <ToastContainer autoClose={3000} />
      <nav className={`kp-navbar ${isShrink ? "kp-navbar-shrink" : ""}`}>
        <div className="kp-navbar-logo">
          <img src={icon} alt="Logo" className="kp-logo-image" />
          <span className="kp-logo-text">
            <Link to="/">Kannur Petroleum</Link>
          </span>
        </div>
        <div>
          <ul
            className={`kp-navbar-links ${
              isMobile ? "kp-navbar-links-mobile kp-active" : ""
            }`}
          >
            <li>
              <a href="/" onClick={() => setIsMobile(false)}>
                Home
              </a>
            </li>
            <li>
              <a href="/Product" onClick={() => setIsMobile(false)}>
                Product
              </a>
            </li>
            <li>
              <a href="/services" onClick={() => setIsMobile(false)}>
                Services
              </a>
            </li>
            <li>
              <a href="/about" onClick={() => setIsMobile(false)}>
                About
              </a>
            </li>
            <li>
              <a href="/contact" onClick={() => setIsMobile(false)}>
                Contact
              </a>
            </li>
            <li>
              <a
                href="/cart"
                onClick={() => setIsMobile(false)}
                className="kp-cart-link"
              >
                <div className="kp-cart-badge">{cartss}</div>
                <FaCartShopping className="kp-cart-icon" />
              </a>
            </li>
          </ul>
        </div>
        <div className="kp-navbar-icons" onClick={handleMenuToggle}>
          {isMobile ? (
            <FaTimes className="kp-menu-icon kp-close-icon" />
          ) : (
            <FaBars className="kp-menu-icon" />
          )}
        </div>
      </nav>
    </>
  );
};

export default Navbar;
