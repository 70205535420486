import React from "react";
import Home from "../components/Home/Home";
import Services from "../components/Services";
import WhyChooseUs from "../components/WhyChooseUs";

import MissionVisionComponent from "../components/MissionVisionComponent";
import Team from "../components/Team";

function HomePage() {
  return (
    <div>
      <Home />
      <WhyChooseUs />
      <MissionVisionComponent />
      <Services />
      <Team />
      {/* <Testimonials /> */}
    </div>
  );
}

export default HomePage;
