import React from "react";
import "../styles/Privacy.css";

const Privacy = () => {
  return (
    <div className="privacy-container">
      <h1 className="privacy-title">Privacy Policy</h1>
      <p className="privacy-text">
        At KANNUR DISTRICT PETROLEUM DEALERS WELFARE & CHARITABLE TRUST, we are
        committed to protecting your privacy. This Privacy Policy outlines how
        we collect, use, and safeguard your information when you visit our
        website and engage with our services.
      </p>

      <h2 className="privacy-section-title">1. Information We Collect</h2>
      <p className="privacy-text">
        We may collect the following types of information:
      </p>
      <ul className="privacy-list">
        <li className="privacy-list-item">
          <strong>Personal Information:</strong> Information you provide when
          creating an account, contacting us, or making a purchase, including
          your name, email address, phone number, and payment details.
        </li>
        <li className="privacy-list-item">
          <strong>Business Information:</strong> Information related to your
          company, such as business name, industry, and any other relevant data
          for establishing a partnership.
        </li>
        <li className="privacy-list-item">
          <strong>Website Usage Data:</strong> Information automatically
          collected through cookies and analytics tools, such as IP address,
          browser type, and pages viewed.
        </li>
      </ul>

      <h2 className="privacy-section-title">2. How We Use Your Information</h2>
      <p className="privacy-text">We use the information we collect to:</p>
      <ul className="privacy-list">
        <li className="privacy-list-item">
          Process transactions and manage your account.
        </li>
        <li className="privacy-list-item">
          Provide customer service and respond to inquiries.
        </li>
        <li className="privacy-list-item">
          Improve our website, products, and services.
        </li>
        <li className="privacy-list-item">
          Send relevant updates and promotions.
        </li>
      </ul>

      <h2 className="privacy-section-title">3. Sharing Your Information</h2>
      <p className="privacy-text">
        We do not sell or trade your personal information. However, we may share
        information with third-party service providers that help us operate our
        website and services, such as payment processors and analytics
        providers. These providers are contractually obligated to protect your
        information and only use it as necessary to perform their functions.
      </p>

      <h2 className="privacy-section-title">4. Data Security</h2>
      <p className="privacy-text">
        We implement security measures to protect your information from
        unauthorized access, alteration, and disclosure. However, please note
        that no online data transmission is 100% secure. We encourage you to use
        strong passwords and take precautions when sharing information online.
      </p>

      <h2 className="privacy-section-title">5. Cookies</h2>
      <p className="privacy-text">
        Our website uses cookies to enhance your browsing experience and analyze
        site traffic. You can control cookie settings through your browser,
        though disabling cookies may affect certain features on our site.
      </p>

      <h2 className="privacy-section-title">6. Your Rights</h2>
      <p className="privacy-text">
        You have the right to access, update, or delete your personal
        information. If you would like to exercise these rights, please contact
        us using the information below.
      </p>

      <h2 className="privacy-section-title">7. Contact Us</h2>
      <address className="privacy-address">
        KANNUR DISTRICT PETROLEUM DEALERS WELFARE & CHARITABLE TRUST
        <br />
        RASHTRA Deepika road,
        <br />
        Pallikkunnu, Kannur, Kerala 670004
        <br />
        Email: kdpdwct@gmail.com
        <br />
        Phone: +91 6238204046
      </address>

      <p className="privacy-text">
        By using our website, you consent to this Privacy Policy. We may update
        this policy periodically, and any changes will be posted on this page.
      </p>
    </div>
  );
};

export default Privacy;
