import React from "react";
import "../styles/Services.css";

const services = [
  {
    title: "Personal Protective and Uniform Items",
    description:
      "Comprehensive collection of belts, uniforms, and protective items for workplace safety and comfort.",
    icon: "👔",
  },
  {
    title: "Safety Equipments",
    description:
      "Top-notch safety equipment solutions for optimal protection and peace of mind.",
    icon: "🦺",
  },
  {
    title: "Industrial Components",
    description:
      "A wide range of industrial components including hoses, cables, and nozzles for various applications.",
    icon: "🔩",
  },
  {
    title: "Accounts and Auditing",
    description:
      "Ensure all accounts are accurate and comply with regulatory standards through meticulous auditing.",
    icon: "📊",
  },
  {
    title: "Gardening",
    description:
      "Maintain and beautify retail outlets with dedicated gardening and landscaping services.",
    icon: "🌿",
    highlighted: true,
  },
  {
    title: "Painting",
    description:
      "Professional canopy painting to match specific Oil Marketing Company (OMC) standards and designs.",
    icon: "🎨",
  },
  {
    title: "Cleaning",
    description:
      "Comprehensive canopy cleaning services, ensuring a pristine appearance as per OMC guidelines.",
    icon: "🧼",
  },
  {
    title: "Laboratory Containers and Measuring Tools",
    description:
      "Precision containers, jars, and hydrometers to meet laboratory and testing requirements.",
    icon: "⚗️",
  },
  {
    title: "Maintenance and Cleaning Supplies",
    description:
      "Quality maintenance supplies, such as dust bins and cleaning materials, for a safe and clean environment.",
    icon: "🧽",
  },
];

const Services = () => {
  return (
    <div className="services-section">
      <h2>Our Services</h2>
      <p className="services-description">
        Comprehensive solutions tailored to enhance safety, efficiency, and
        reliability in petroleum operations.
      </p>

      <div className="services-grid">
        {services?.map((service, index) => (
          <div
            key={index}
            className={`service-card ${
              service.highlighted ? "highlighted" : ""
            }`}
          >
            <div className="service-icon">{service.icon}</div>
            <h3>{service.title}</h3>
            <p>{service.description}</p>
            <a href="/" className="read-more">
              Read More
            </a>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Services;
