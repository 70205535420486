import React from "react";
import "../styles/Contact.css";
import ContactUs from "../components/Contact";

function ContactPage() {
  return (
    <div>
      <ContactUs />
    </div>
  );
}

export default ContactPage;
