import React, { useState, useEffect } from "react";
import "../styles/CheckOut.css";
import axios from "axios";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const allStatesOfIndia = [
  "Andhra Pradesh",
  "Arunachal Pradesh",
  "Assam",
  "Bihar",
  "Chhattisgarh",
  "Goa",
  "Gujarat",
  "Haryana",
  "Himachal Pradesh",
  "Jharkhand",
  "Karnataka",
  "Kerala",
  "Madhya Pradesh",
  "Maharashtra",
  "Manipur",
  "Meghalaya",
  "Mizoram",
  "Nagaland",
  "Odisha",
  "Punjab",
  "Rajasthan",
  "Sikkim",
  "Tamil Nadu",
  "Telangana",
  "Tripura",
  "Uttar Pradesh",
  "Uttarakhand",
  "West Bengal",
  "Andaman and Nicobar Islands",
  "Chandigarh",
  "Dadra and Nagar Haveli and Daman and Diu",
  "Lakshadweep",
  "Delhi",
  "Puducherry",
  "Ladakh",
  "Jammu and Kashmir",
];

const CheckOut = () => {
  const [states, setStates] = useState(allStatesOfIndia);
  const [cartItems, setCartItems] = useState(
    JSON.parse(localStorage.getItem("cartItems")) || []
  );
  const [billingSameAsShipping, setBillingSameAsShipping] = useState(true);
  const [payment, setPayment] = useState("online");
  const [showModal, setShowModal] = useState(false);
  const [formData, setFormData] = useState({
    amount: "",
    payment_method: "",
    user_name: "",
    user_email: "",
    user_mobile_no: "",
    user_address: "",
    user_state: "Andhra Pradesh",
    user_city: "",
    user_zipcode: "",
    billing_address: "",
    product_details: [],
  });
  const [billing, setBilling] = useState({
    user_name: "",
    user_mobile_no: "",
    user_address: "",
    user_state: "Andhra Pradesh",
    user_city: "",
    user_zipcode: "",
  });
  const [errors, setErrors] = useState({});

  const calculateTotal = () => {
    return cartItems.reduce(
      (total, item) => total + item.p_price * item.quantity,
      0
    );
  };

  useEffect(() => {
    setFormData((prevFormData) => ({
      ...prevFormData,
      amount: calculateTotal(),
      payment_method: payment,
      product_details: cartItems.map((item) => ({
        product_id: item.p_id,
        quantity: item.quantity,
      })),
    }));
  }, [cartItems, payment]);

  const validate = () => {
    let newErrors = {};
    if (!formData.user_name) newErrors.user_name = "Full Name is required";
    if (!formData.user_email || !/\S+@\S+\.\S+/.test(formData.user_email))
      newErrors.user_email = "Valid email is required";
    if (!formData.user_mobile_no || !/^\d{10}$/.test(formData.user_mobile_no))
      newErrors.user_mobile_no = "Valid 10-digit phone number is required";
    if (!formData.user_address) newErrors.user_address = "Address is required";
    if (!formData.user_city) newErrors.user_city = "City is required";
    if (!formData.user_state) newErrors.user_state = "State is required";
    if (!formData.user_zipcode || !/^\d{6}$/.test(formData.user_zipcode))
      newErrors.user_zipcode = "Valid PIN code is required";

    if (!billingSameAsShipping) {
      if (!billing.user_name)
        newErrors.billing_user_name = "Full Name is required";
      if (!billing.user_mobile_no || !/^\d{10}$/.test(billing.user_mobile_no))
        newErrors.billing_user_mobile_no =
          "Valid 10-digit phone number is required";
      if (!billing.user_address)
        newErrors.billing_user_address = "Address is required";
      if (!billing.user_city) newErrors.billing_user_city = "City is required";
      if (!billing.user_state)
        newErrors.billing_user_state = "State is required";
      if (!billing.user_zipcode || !/^\d{6}$/.test(billing.user_zipcode))
        newErrors.billing_user_zipcode = "Valid PIN code is required";
    }

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const CheckOutFunction = () => {
    if (validate()) {
      handleCheckOut();
    } else {
      toast.error("Please fill all required fields correctly.");
    }
  };

  const handleCheckOut = () => {
    const request = {
      ...formData,
      billing_address: billingSameAsShipping
        ? `${formData.user_name},${formData.user_address},${formData.user_city},${formData.user_state},${formData.user_zipcode},${formData.user_mobile_no}`
        : `${billing.user_name},${billing.user_address},${billing.user_city},${billing.user_state},${billing.user_zipcode},${billing.user_mobile_no}`,
    };

    axios
      .post(
        "https://lunarsenterprises.com:6013/kdpetroleum/add-order",
        request,
        {
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
          },
        }
      )
      .then((data) => {
        if (data.data.result) {
          toast.success(data.data.message);
          setShowModal(true);
        } else {
          toast.error(data.data.message);
        }
      })
      .catch((err) => {
        console.log("error", err);
      });
  };

  return (
    <>
      {/* <ToastContainer /> */}
      <div className="checkout-container">
        <div className="checkout-form">
          <h2>Contact</h2>
          <div>
            <input
              type="email"
              value={formData.user_email}
              placeholder="Email"
              onChange={(e) =>
                setFormData((prevFormData) => ({
                  ...prevFormData,
                  user_email: e.target.value,
                }))
              }
              className="input-field"
            />
          </div>
          {errors.user_email && (
            <span className="error-message">{errors.user_email}</span>
          )}

          <h2>Delivery</h2>
          <select className="input-field">
            <option>India</option>
          </select>
          <div>
            <input
              value={formData.user_name}
              onChange={(e) =>
                setFormData((prevFormData) => ({
                  ...prevFormData,
                  user_name: e.target.value,
                }))
              }
              type="text"
              placeholder="Full Name"
              className="input-field"
            />
          </div>
          {errors.user_name && (
            <span className="error-message">{errors.user_name}</span>
          )}
          <div className="address-row">
            <input
              value={formData.user_address}
              onChange={(e) =>
                setFormData((prevFormData) => ({
                  ...prevFormData,
                  user_address: e.target.value,
                }))
              }
              type="text"
              placeholder="House name/ building name & Area/Colony"
              className="input-field"
            />
          </div>
          {errors.user_address && (
            <span className="error-message">{errors.user_address}</span>
          )}
          <div className="address-row">
            <input
              value={formData.user_city}
              onChange={(e) =>
                setFormData((prevFormData) => ({
                  ...prevFormData,
                  user_city: e.target.value,
                }))
              }
              type="text"
              placeholder="City"
              className="input-field"
            />

            <select
              className="input-field"
              value={formData.user_state}
              onChange={(e) =>
                setFormData((prevFormData) => ({
                  ...prevFormData,
                  user_state: e.target.value,
                }))
              }
            >
              {states.map((item, index) => (
                <option key={index}>{item}</option>
              ))}
            </select>

            <input
              type="text"
              placeholder="Pincode"
              className="input-field"
              value={formData.user_zipcode}
              onChange={(e) =>
                setFormData((prevFormData) => ({
                  ...prevFormData,
                  user_zipcode: e.target.value,
                }))
              }
            />
          </div>
          <div style={{ display: "flex", justifyContent: "space-between" }}>
            {errors.user_city && (
              <span className="error-message">{errors.user_city}</span>
            )}
            {errors.user_zipcode && (
              <span className="error-message">{errors.user_zipcode}</span>
            )}
          </div>
          <div>
            <input
              type="tel"
              placeholder="Phone"
              className="input-field"
              value={formData.user_mobile_no}
              onChange={(e) =>
                setFormData((prevFormData) => ({
                  ...prevFormData,
                  user_mobile_no: e.target.value,
                }))
              }
            />
          </div>
          {errors.user_mobile_no && (
            <span className="error-message">{errors.user_mobile_no}</span>
          )}
          <h2>Shipping Method</h2>
          <div className="shipping-method">
            <p>
              Enter your shipping address to view available shipping methods.
            </p>
          </div>

          <h2>Payment</h2>
          <p className="payment-info">
            All transactions are secure and encrypted.
          </p>
          <div className="payment-options">
            <label className="payment-option">
              <input
                type="radio"
                name="payment"
                value="online"
                checked={payment === "online"}
                onChange={() => setPayment("online")}
              />
              Pay Online
            </label>
            <label className="payment-option">
              <input
                type="radio"
                name="payment"
                value="cash on delivery"
                checked={payment === "cash on delivery"}
                onChange={() => setPayment("cash on delivery")}
              />
              Cash on Delivery (COD)
            </label>
            {payment === "cash on delivery" && (
              <p className="payment-description">Pay at your Doorstep</p>
            )}
          </div>
          {payment === "cash on delivery" && (
            <>
              <h2>Billing Address</h2>
              <label className="billing-option">
                <input
                  type="radio"
                  name="billing"
                  checked={billingSameAsShipping}
                  onChange={() => setBillingSameAsShipping(true)}
                />{" "}
                Same as shipping address
              </label>
              <label className="billing-option">
                <input
                  type="radio"
                  name="billing"
                  checked={!billingSameAsShipping}
                  onChange={() => setBillingSameAsShipping(false)}
                />{" "}
                Use a different billing address
              </label>
              {!billingSameAsShipping && (
                <>
                  <select className="input-field">
                    <option value="india">India</option>
                  </select>
                  <input
                    type="text"
                    placeholder="Full Name"
                    className="input-field"
                    value={billing.user_name}
                    onChange={(e) =>
                      setBilling((prevFormData) => ({
                        ...prevFormData,
                        user_name: e.target.value,
                      }))
                    }
                  />
                  {errors.billing_user_name && (
                    <p className="error-text">{errors.billing_user_name}</p>
                  )}
                  <div className="address-row">
                    <input
                      type="text"
                      placeholder="House name/ building name & Area/Colony"
                      className="input-field"
                      value={billing.user_address}
                      onChange={(e) =>
                        setBilling((prevFormData) => ({
                          ...prevFormData,
                          user_address: e.target.value,
                        }))
                      }
                    />
                  </div>
                  {errors.billing_user_address && (
                    <p className="error-text">{errors.billing_user_address}</p>
                  )}
                  <div className="address-row">
                    <input
                      type="text"
                      placeholder="City"
                      className="input-field"
                      value={billing.user_city}
                      onChange={(e) =>
                        setBilling((prevFormData) => ({
                          ...prevFormData,
                          user_city: e.target.value,
                        }))
                      }
                    />

                    <select
                      className="input-field"
                      value={billing.user_state}
                      onChange={(e) =>
                        setBilling((prevFormData) => ({
                          ...prevFormData,
                          user_state: e.target.value,
                        }))
                      }
                    >
                      {states.map((item, index) => (
                        <option key={index}>{item}</option>
                      ))}
                    </select>

                    <input
                      type="text"
                      placeholder="Pincode"
                      className="input-field"
                      value={billing.user_zipcode}
                      onChange={(e) =>
                        setBilling((prevFormData) => ({
                          ...prevFormData,
                          user_zipcode: e.target.value,
                        }))
                      }
                    />
                  </div>
                  <div
                    style={{ display: "flex", justifyContent: "space-between" }}
                  >
                    {errors.billing_user_city && (
                      <p className="error-text">{errors.billing_user_city}</p>
                    )}{" "}
                    {errors.billing_user_zipcode && (
                      <p className="error-text">
                        {errors.billing_user_zipcode}
                      </p>
                    )}
                  </div>
                  <input
                    type="tel"
                    placeholder="Phone"
                    className="input-field"
                    value={billing.user_mobile_no}
                    onChange={(e) =>
                      setBilling((prevFormData) => ({
                        ...prevFormData,
                        user_mobile_no: e.target.value,
                      }))
                    }
                  />
                  {errors.billing_user_mobile_no && (
                    <p className="error-text">
                      {errors.billing_user_mobile_no}
                    </p>
                  )}
                </>
              )}
            </>
          )}
        </div>

        <div className="order-summary">
          {cartItems.length > 0 ? (
            cartItems.map((item) => (
              <div className="order-item" key={item.p_id}>
                <img
                  src={`https://lunarsenterprises.com:6013/${item.p_image}`}
                  alt={item.p_name}
                  className="item-image"
                />
                <div className="item-details">
                  <p>{item.p_name}</p>
                </div>
                <p className="item-price">₹{item.p_price}</p>
                <div className="summary-details">
                  <p>Quantity</p>
                  <p>{item.quantity}</p>
                </div>
              </div>
            ))
          ) : (
            <p>Your cart is empty.</p>
          )}
        </div>
      </div>
      <div className="summary-container">
        <div className="summary-details">
          <p>Subtotal</p>
          <p>INR {calculateTotal()}.00</p>
        </div>
        <div className="summary-details">
          <p>Shipping</p>
          <p>Enter shipping address</p>
        </div>
        <div className="summary-total">
          <p>Total</p>
          <p>INR {calculateTotal()}.00</p>
        </div>
      </div>
      <div className="complete-order-button">
        <button
          disabled={cartItems.length ? false : true}
          onClick={CheckOutFunction}
        >
          Complete Order
        </button>
      </div>
      {showModal && (
        <div className="checkout-modal-overlay">
          <div className="checkout-modal-content">
            <h2>Order Placed Successfully!</h2>
            <p>Your order has been placed successfully.</p>
            <button onClick={() => setShowModal(false)}>Close</button>
          </div>
        </div>
      )}
    </>
  );
};

export default CheckOut;
