import React from "react";
import { motion, useAnimation } from "framer-motion";
import { useInView } from "react-intersection-observer";
import "../styles/Footer.css";

function Footer() {
  const controls = useAnimation();
  const [ref, inView] = useInView({
    triggerOnce: true,
    threshold: 0.1,
  });

  React.useEffect(() => {
    if (inView) {
      controls.start({
        opacity: 1,
        y: 0,
        transition: { duration: 0.6, ease: "easeOut" },
      });
    }
  }, [controls, inView]);

  return (
    <motion.footer
      className="mollin-footer"
      ref={ref}
      initial={{ opacity: 0, y: 50 }}
      animate={controls}
    >
      <div className="mollin-footer-container">
        <div className="mollin-footer-section mollin-brand">
          <h2>Kannur Petroleum</h2>
          <p>Your Trusted Partner in Petroleum Solutions.</p>
          {/* <div className="mollin-social-icons">
            <span className="mollin-icon fb-icon">F</span>
            <span className="mollin-icon tw-icon">T</span>
            <span className="mollin-icon gp-icon">G+</span>
            <span className="mollin-icon ln-icon">in</span>
          </div> */}
        </div>

        <div className="mollin-footer-section mollin-links">
          <h3>Useful Links</h3>
          <ul>
            {/* <li>
              <a href="/">Support</a>
            </li> */}
            <li>
              <a href="/Product">Product</a>
            </li>
            <li>
              <a href="/services">Service</a>
            </li>
            <li>
              <a href="/contact">Contact</a>
            </li>
            <li>
              <a href="/about">About</a>
            </li>
            <li>
              <a href="/Privacy">Privacy Policy</a>
            </li>
            <li>
              <a href="/terms">Terms & Conditions</a>
            </li>
            <li>
              <a href="/CancelPolicy">Cancellation/Refund Policies</a>
            </li>
          </ul>
        </div>

        <div className="mollin-footer-section mollin-contact">
          <h3>Get in Touch</h3>
          <p>🏠 RASHTRA Deepika road, Pallikkunnu, Kannur, Kerala 670004</p>
          <p>📞 +91 6238204046</p>
          <p>📧 kdpdwct@gmail.com</p>
        </div>

        <div className="mollin-footer-section mollin-partners">
          <h3>Our Partners</h3>
          <ul>
            <li>
              <a href="https://www.bharatpetroleum.in/">Bharat Petroleum</a>
            </li>
            <li>
              <a href="https://www.hindustanpetroleum.com/">
                Hindustan Petroleum
              </a>
            </li>
            <li>
              <a href="https://iocl.com/">Indian Oil</a>
            </li>
          </ul>
        </div>
      </div>

      <div className="mollin-footer-bottom">
        <p>Copyright © 2024 . All rights reserved</p>
      </div>
    </motion.footer>
  );
}

export default Footer;
